import moment from 'moment';

export const daysUntilTwentieth = (): number => {
  const today = moment();
  const startOfCurrentMonth = moment().startOf('month');
  const twentiethOfCurrentMonth = startOfCurrentMonth.add(20, 'days');
  // banner appears from 1st to 20th so "today" will always be less than 20th
  const diffDays = twentiethOfCurrentMonth.diff(today, 'days');
  return diffDays;
};
