interface RoleType {
  translationKey: string;
  apiKey: string;
}

export const roles: RoleType[] = [
  { translationKey: 'superAdmin', apiKey: 'superAdmin' },
  { translationKey: 'IT', apiKey: 'IT' },
  { translationKey: 'marketing', apiKey: 'marketing' },
  { translationKey: 'operator', apiKey: 'operator' },
  {
    translationKey: 'analytics',
    apiKey: 'analytics',
  },
  {translationKey:'cashier',apiKey:'cashier'},

];

export enum CompanyEmployeesRoles {
  superAdmin = 'superAdmin',
  IT = 'IT',
  marketing = 'marketing',
  operator = 'operator',
  analytics = 'analytics',
  cashier='cashier'
}

export const permissions = {
  all: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
    CompanyEmployeesRoles.marketing,
    CompanyEmployeesRoles.analytics,
  ],
  settings: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  staff: [CompanyEmployeesRoles.superAdmin],
  billing: [CompanyEmployeesRoles.superAdmin],
  logger: [CompanyEmployeesRoles.superAdmin],
  doorAccess: [CompanyEmployeesRoles.IT, CompanyEmployeesRoles.superAdmin],
  companyAndBranchesRead: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  management: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  gateway: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  traffic: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  applicationfiltration: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  policy: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  policyAddEditDelete: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.IT,
  ],
  pendingActivation: [CompanyEmployeesRoles.superAdmin],
  lines: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  sessions: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  users: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  devices: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  loginPageBuilder: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.operator,
    CompanyEmployeesRoles.IT,
  ],
  changeLoginPageBuilder: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.IT,
  ],
  analytics: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.marketing,
    CompanyEmployeesRoles.analytics,
  ],
  marketing: [
    CompanyEmployeesRoles.superAdmin,
    CompanyEmployeesRoles.marketing,
  ],
};
